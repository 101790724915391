import React, { createContext, useState, useContext, ReactNode } from 'react'
import { ThemeType, useTheme, CombinedDefaultTheme } from '../hooks/use-theme'
import { Dimensions, StyleSheet } from 'react-native'

const createStyles = (theme: ThemeType) =>
  StyleSheet.create({
    headerTitle: {
      fontSize: theme.fonts.titleMedium.fontSize,
      fontWeight: theme.fonts.titleMedium.fontWeight,
      fontFamily: theme.fonts.titleMedium.fontFamily,
    },
    pageContainer: {
      padding: theme.components.pageContainer.padding,
      alignContent: 'stretch',
      flexDirection: 'column',
      maxHeight: Dimensions.get('window').height - 64,
    },
    row: {
      marginVertical: theme.spacing(0),
    },
    flex: {
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'center',
    },
    flexNoWrap: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
    },
    // to align button next to a intput field
    formButtonWrapper: {
      paddingTop: theme.spacing(1),
    },
    verticalSpacer: {
      height: theme.spacing(6),
    },
    horizontalSpacer: {
      width: theme.spacing(8),
    },
  })
type GlobalStyles = ReturnType<typeof createStyles>

const INITIAL_VALUE: GlobalStyles = createStyles(CombinedDefaultTheme)
export const GlobalStyleContext = createContext<GlobalStyles>(INITIAL_VALUE)

type GlobalStyleProviderProps = {
  children: ReactNode
}
export const GlobalStylesProvider = ({
  children,
}: GlobalStyleProviderProps) => {
  const theme = useTheme()
  const value = React.useMemo(() => createStyles(theme), [theme])

  return (
    <GlobalStyleContext.Provider value={value}>
      {children}
    </GlobalStyleContext.Provider>
  )
}

export const useGlobalStyles = () => {
  return useContext(GlobalStyleContext)
}
