import * as React from 'react'
import { Appbar } from 'react-native-paper'
import { getHeaderTitle } from '@react-navigation/elements'
import { StackHeaderProps, StackNavigationProp } from '@react-navigation/stack'
import { AppNavigatorParams } from '../navigation/types'
import NavigationBarMenu from './NavigationBarMenu'
import { useTheme } from '../hooks/use-theme'
import { useTranslation } from 'react-i18next'

export interface MelddNavigationBarProps extends StackHeaderProps {
  navigation: StackNavigationProp<AppNavigatorParams>
}

export function MelddNavigationBar({
  navigation,
  route,
  options,
  back,
}: MelddNavigationBarProps) {
  const theme = useTheme()
  const { t } = useTranslation('screens')

  const title = t(getHeaderTitle(options, route.name))

  return (
    <Appbar.Header style={{ backgroundColor: theme.colors.secondaryContainer }}>
      {back ? <Appbar.BackAction onPress={navigation.goBack} /> : null}
      <Appbar.Content title={title} />
      <NavigationBarMenu navigation={navigation} />
    </Appbar.Header>
  )
}
