import * as React from 'react'
import {
  FlatList,
  Image,
  ListRenderItem,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import { MenuItem, MenuItemSpec } from './types'

interface MenuState {
  [action: string]: { active: boolean; enabled: boolean }
}

import { MaterialCommunityIcons } from '@expo/vector-icons'

interface ToolbarProps {
  editorState: MenuState
  onMenuItem: (msg: any) => void
  iconTint?: string
  iconSize?: number
  iconGap?: number
  inactiveButtonStyle?: ViewStyle
  activeButtonStyle?: ViewStyle
  disabledButtonStyle?: ViewStyle
  itemStyle?: TextStyle
}

const styles = StyleSheet.create({
  item: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRightColor: '#E7E7E7',
    borderRightWidth: 1,
    borderLeftColor: '#E7E7E7',
    borderLeftWidth: 1,
  },
  richBar: {
    height: 40,
    backgroundColor: 'white',
    borderColor: '#E7E7E7',
    borderRadius: 1,
    borderWidth: 1,
    shadowColor: '#171717',
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    marginBottom: 15,
    width: '98%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})
const items: MenuItemSpec[] = [
  {
    action: 'bold',
    message: { type: 'toggleMark', mark: 'strong' },
    icon: 'format-bold',
  },
  {
    action: 'em',
    message: { type: 'toggleMark', mark: 'em' },
    icon: 'format-italic',
  },
  // {action: 'code', message: {type: 'toggleMark', mark: 'code'}, icon: 'code-braces'},
  {
    action: 'paragraph',
    message: { type: 'blockType', block: 'paragraph' },
    icon: 'format-paragraph',
  },
  {
    action: 'heading1',
    message: { type: 'blockType', block: 'heading', attrs: { level: 1 } },
    icon: 'format-header-1',
  },
  {
    action: 'heading2',
    message: { type: 'blockType', block: 'heading', attrs: { level: 2 } },
    icon: 'format-header-2',
  },
  {
    action: 'heading3',
    message: { type: 'blockType', block: 'heading', attrs: { level: 3 } },
    icon: 'format-header-3',
  },
  {
    action: 'bulletList',
    message: { type: 'wrapList', node: 'bullet_list' },
    icon: 'format-list-bulleted',
  },
  {
    action: 'orderedList',
    message: { type: 'wrapList', node: 'ordered_list' },
    icon: 'format-list-numbered',
  },
  { action: 'lift', message: { type: 'lift' }, icon: 'format-indent-decrease' },
  {
    action: 'joinUp',
    message: { type: 'joinUp' },
    icon: 'arrow-expand-vertical',
  },
]

export function Toolbar(props: ToolbarProps) {
  const {
    editorState,
    onMenuItem,
    activeButtonStyle,
    disabledButtonStyle,
    inactiveButtonStyle,
    iconGap = 15,
    iconSize = 20,
    iconTint = 'black',
  } = props
  const menuItems: MenuItem[] = React.useMemo(
    () =>
      items.map((spec) => {
        return {
          ...spec,
          enabled: editorState[spec.action]?.enabled || false,
          active: editorState[spec.action]?.active || false,
        }
      }),
    [editorState]
  )
  const renderItem: ListRenderItem<MenuItem> = ({ item }) => {
    // console.log('rendering item', item)
    const { enabled, active } = item
    const style = enabled
      ? active
        ? activeButtonStyle
        : inactiveButtonStyle
      : disabledButtonStyle
    const buttonSize = 38
    return (
      <MaterialCommunityIcons.Button
        disabled={!item.enabled}
        name={item.icon}
        onPress={() => onMenuItem(item.message)}
        color={item.enabled ? iconTint : item.active ? iconTint : '#aaa'}
        backgroundColor={
          item.active ? (item.enabled ? 'blue' : '#aaf') : 'white'
        }
        borderRadius={1}
        size={iconSize}
        style={{
          padding: 0,
          margin: 0,
          width: buttonSize,
          height: buttonSize,
          borderRightColor: '#00000012',
          borderRightWidth: 1,
        }}
        iconStyle={{ marginLeft: 8, padding: 0 }}
      />
    )
  }

  return (
    <View style={styles.richBar}>
      <FlatList
        horizontal
        keyboardShouldPersistTaps={'always'}
        keyExtractor={(item, index) => item.action + '-' + index}
        data={menuItems}
        alwaysBounceHorizontal={false}
        showsHorizontalScrollIndicator={false}
        renderItem={renderItem}
      />
    </View>
  )
}

Toolbar.defaultProps = {
  iconTint: 'black',
  iconSize: 20,
  iconGap: 10,
}
