import * as React from 'react'
import { Appbar, Divider, Menu } from 'react-native-paper'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IconFlagChina,
  IconFlagUK,
} from '../utils/meldd-icons'
import { useFeatures } from '../contexts/FeatureProvider'

export default function LanguageButtonMenu() {
  const [visible, setVisible] = React.useState(false)
  const { langCn, setLangCn } = useFeatures()
  const { t } = useTranslation('menuItems')

  const anchor = useMemo(() => {
    return (
      <Appbar.Action
        icon={langCn ? IconFlagChina : IconFlagUK}
        onPress={() => setVisible(true)}
      />
    )
  }, [langCn])
  return (
    <Menu
      visible={visible}
      onDismiss={() => setVisible(false)}
      anchorPosition={'bottom'}
      anchor={anchor}
    >
      <Menu.Item
        leadingIcon={IconFlagUK}
        onPress={() => {
          setLangCn(false)
          setVisible(false)
        }}
        title={t('English')}
      />
      <Divider />
      <Menu.Item
        leadingIcon={IconFlagChina}
        onPress={() => {
          setLangCn(true)
          setVisible(false)
        }}
        title={t('Chinese')}
      />
    </Menu>
  )
}
