import { ApolloError } from '@apollo/client'
import { useTranslation } from 'react-i18next'

export const useErrorTranslation = () => {
  const { t, i18n } = useTranslation('errors')
  return (error: unknown) => {
    if (error === undefined) {
      return ''
    }
    if (typeof error == 'string') {
      return t(error)
    }
    
    if (error instanceof ApolloError) {
      const { graphQLErrors, networkError } = error

      if (networkError) {
        return t(networkError.message)
      }
      
      if (graphQLErrors?.[0]) {
        const code = graphQLErrors[0].extensions?.code
        const tCode = t(code as string)
        if (code && tCode != code) {
          return tCode
        }
        // The `t` will parse or cut the original error.
        return i18n.exists(`errors.${graphQLErrors[0].message}`)
          ? t(graphQLErrors[0].message)
          : graphQLErrors[0].message
      }

     
      return t(error.message)
    }
    if (error instanceof Error) {
      return t(error.message)
    }

    return t('UNKNOWN')
  }
}
export const getErrorCode = (error: any) => {
  if (error instanceof ApolloError) {
    const { graphQLErrors, networkError } = error
    if (graphQLErrors) {
      return graphQLErrors[0]?.extensions?.code
    }
  }
  return undefined
}
