import { createContext, ReactNode, useContext, useMemo } from 'react'
import { AuthContext } from './auth-context'
import { ApolloProvider } from '@apollo/client'
import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { Env } from '../env'

let id = 0
/**
 * A wrapper around ApolloProvider to connect the client to AuthContext
 *
 * @param {{children: React.ReactNode}} props
 * @return {JSX.Element}
 *
 */
export const AuthClientProvider = (props: { children: ReactNode }) => {
  const { token, clearCurrentUser } = useContext(AuthContext)
  const cache = useMemo(
    () =>
      new InMemoryCache({
        typePolicies: {
          ContributionBump: {
            keyFields: ['contributionId', 'userId'],
          },
        },
      }),
    []
  )
  const client = useMemo(() => {
    const myId = id++
    // console.log('AuthClientProvider: generating new client', myId)

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach((error) => {
          const { message, locations, path, extensions } = error
          if (message === 'Unauthorized') {
            console.log('AuthClientProvider: Unauthorized, logging out')
            clearCurrentUser()
          }
          console.warn(
            `AuthClientProvider:[GraphQL error]: ${JSON.stringify(
              error,
              null,
              2
            )}`
          )
        })
      if (networkError) console.log(`[Network error]: ${networkError}`)
    })
    const httpLink = new HttpLink({
      uri: Env.API_URL,
    })

    const authLink = setContext(async (req, { headers }) => {
      console.log('AuthClientProvider:token ', myId, token)
      return {
        ...headers,
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      }
    })

    const link = authLink.concat(httpLink)

    return new ApolloClient({
      connectToDevTools: true,
      defaultOptions: {
        query: {},
      },
      cache,
      link: from([errorLink, link /* httpLink */]),
    })
  }, [token, clearCurrentUser])

  return <ApolloProvider client={client} {...props} />
}
