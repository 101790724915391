import * as React from 'react'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  AvailableNodesQuery,
  useAvailableNodesLazyQuery,
} from '../generated/graphql'
import { useAuthContext } from '../auth/auth-context'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Unpacked } from '../utils/types'
import { MdNode } from '../screens/ListNodes/types'
import { ApolloError, ApolloQueryResult } from '@apollo/client'
import Toast from 'react-native-root-toast'
import { MessageType } from '../utils/message-type'
import { orderBy, sortBy } from 'lodash'
import { l } from 'vitest/dist/index-5aad25c1'

interface CurrentNodeProviderProps {
  children: ReactNode
}

interface CurrentNodeProviderShape {
  currentNode?: NdNode
  setCurrentNode: (node?: NdNode) => void
  setNodeId: (nodeId?: string) => void
  nodes: NdNode[]
  refetch: (nodeId?: string) => Promise<MdNode[]>
  loading: boolean
  error?: ApolloError
}

const CURRENT_NODE_KEY = 'currentNode'

const CurrentNodeContext = createContext<CurrentNodeProviderShape>({
  currentNode: undefined,
  setCurrentNode: () => {},
  setNodeId: () => {},
  nodes: [],
  refetch: async (nodeId?: string) => [],
  loading: false,
  error: undefined,
})

type NdNode = Unpacked<AvailableNodesQuery['availableNodes']>

export function CurrentNodeProvider(props: CurrentNodeProviderProps) {
  const { userId } = useAuthContext()
  const [currentNode, _setCurrentNode] = useState<MdNode>()
  const [nodes, setNodes] = useState<MdNode[]>([])
  const [loading, setLoading] = useState(true)
  const nextNodeRef = useRef<string>()
  const [query, { error }] = useAvailableNodesLazyQuery({
    fetchPolicy: 'cache-and-network',
  })

  const refetch = useCallback(
    async (nodeId?: string) => {
      if (userId) {
        const lastNode = await AsyncStorage.getItem(
          `${CURRENT_NODE_KEY}:${userId}`
        )
        try {
          setLoading(true)
          const result = await query()
          const n = orderBy(
            result.data?.availableNodes || [],
            ['isPrivate', 'name'],
            ['asc', 'asc']
          )
          setNodes(n)
          let toSelect = undefined
          if (nodeId) {
            toSelect = n.find((n) => n.id == nodeId) || toSelect
            console.log('current-node-provider:refetch', nodeId, toSelect)
          }
          if (!toSelect && nextNodeRef.current) {
            toSelect = n.find((n) => n.id == nextNodeRef.current) || toSelect
          }
          if (!toSelect && lastNode) {
            toSelect = n.find((n) => n.id == lastNode) || toSelect
          }
          _setCurrentNode(toSelect)
          setLoading(false)
          return n
        } catch (e) {
          Toast.show((e as Error).message, MessageType.error)
          setLoading(false)

          return []
        }
      } else {
        setNodes([])
        return []
      }
    },
    [query, userId]
  )

  useEffect(() => {
    refetch().then()
  }, [userId])

  const setCurrentNode = useCallback((node?: NdNode) => {
    if (node) {
      AsyncStorage.setItem(`${CURRENT_NODE_KEY}:${userId}`, node?.id)
    } else {
      AsyncStorage.removeItem(`${CURRENT_NODE_KEY}:${userId}`)
    }
    _setCurrentNode(node)
  }, [])
  const setNodeId = useCallback(
    (nodeId?: string) => {
      console.log('current-node-provider:setNodeId', nodeId)
      if (loading) {
        console.log('current-node-provider:setNodeId is loading')
        nextNodeRef.current = nodeId
      } else {
        const toSelect = nodes.find((n) => n.id == nodeId)
        console.log('current-node-provider:setNodeId toSelect', toSelect)
        if (toSelect) {
          setCurrentNode(toSelect)
        }
      }
    },
    [loading, nodes]
  )

  const value: CurrentNodeProviderShape = useMemo(
    () => ({
      currentNode,
      setCurrentNode,
      nodes,
      refetch,
      loading,
      error,
      setNodeId,
    }),
    [currentNode, setCurrentNode, nodes, loading, error]
  )

  return <CurrentNodeContext.Provider value={value} {...props} />
}

export const useCurrentNode = (nodeId?: string) => {
  const context = useContext(CurrentNodeContext)
  if (nodeId && context.currentNode?.id != nodeId) {
    context.setNodeId(nodeId)
  }
  return context
}
