import { createStackNavigator, StackHeaderProps } from '@react-navigation/stack'
import { RegisterScreen } from '../screens/Register'
import { PrivacyPolicyScreen } from '../screens/PrivacyPolicy'
import { AuthNavigatorParams } from '../navigation/types'
import { useTranslation } from 'react-i18next'
import { Text } from 'react-native'
import { useGlobalStyles } from '../contexts/GlobalStylesProvider'
import { MelddNavigationBarProps } from '../components/MelddNavigationBar'
import { LoginNavigationBar } from '../components/LoginNavigationBar'
import { LoginMobileNumberScreen } from '../screens/Login/LoginMobileNumber'

const Stack = createStackNavigator<AuthNavigatorParams>()

const AuthNavigator = () => {
  const { t } = useTranslation('screens')
  const styles = useGlobalStyles()
  return (
    <Stack.Navigator
      screenOptions={({ navigation, route }) => ({
        header: (props: StackHeaderProps) => (
          <LoginNavigationBar {...(props as MelddNavigationBarProps)} />
        ),

        headerTitle: (props) => (
          <Text style={styles.headerTitle}>{t(route.name)}</Text>
        ),
      })}
    >
      <Stack.Screen
        name="LoginMobileNumber"
        component={LoginMobileNumberScreen}
      />
      <Stack.Screen name="Register" component={RegisterScreen} />
      <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen} />
    </Stack.Navigator>
  )
}

export default AuthNavigator
