import * as React from 'react'
import { StyleSheet, Pressable } from 'react-native'
import { Text } from 'react-native-paper'
import { useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../../../navigation/types'

type ClickableClusterMemberProps = {
  user: { username: string, id: string }
  onPress?: (user: { username: string, id: string }) => void
}

const styles = StyleSheet.create({
  member: {
    marginHorizontal: 5,
    alignSelf: 'center',
    textDecorationLine: 'underline',
  },
})

export function UserLink(props: ClickableClusterMemberProps) {
  const navigation = useNavigation<AppNavigation>()
  const { user, onPress } = props
  return (
    <Pressable style={{ flexShrink: 1 }} onPress={() => onPress ? onPress(user) : navigation.navigate('Profile', { userId: user.id })}>
      <Text
        variant={'bodySmall'}
        numberOfLines={1}
        ellipsizeMode={'tail'}
        style={styles.member}
      >
        {user.username}
      </Text>
    </Pressable>
  )
}
