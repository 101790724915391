import React, { createContext, useState, useContext, ReactNode } from 'react'

export type VisualMode = 'default' | 'graphic'
type VisualModeContextType = {
  visualMode: VisualMode
  setVisualMode: React.Dispatch<React.SetStateAction<VisualMode>>
}

export const VisualModeContext = createContext<VisualModeContextType>({
  visualMode: 'default',
  setVisualMode: () => {},
})

type VisualModeProviderProps = {
  children: ReactNode
}

export const VisualModeProvider = ({ children }: VisualModeProviderProps) => {
  const [visualMode, setVisualMode] = useState<VisualMode>('default')

  return (
    <VisualModeContext.Provider value={{ visualMode, setVisualMode }}>
      {children}
    </VisualModeContext.Provider>
  )
}

export const useVisualMode = () => {
  const { visualMode, setVisualMode } = useContext(VisualModeContext)

  return { visualMode, setVisualMode }
}
