import * as React from 'react'
import { View, StyleSheet, ViewProps, ViewStyle, StyleProp } from 'react-native'
import { Surface, Text } from 'react-native-paper'
import { IconCluster, IconNode, IconPerspective } from '../utils/meldd-icons'
import { Icon } from './Icon'
import { useTheme } from '../hooks/use-theme'
import { useMemo } from 'react'

interface LevelIndicatorProps {
  level: number
  flat?: boolean
  style?: StyleProp<ViewStyle>
}

const icons = [IconNode, IconCluster, IconPerspective]
const iconSizes = [18, 22, 12]
export function LevelIndicator(props: LevelIndicatorProps) {
  const { level, flat, style } = props
  const theme = useTheme()
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'row',
          borderRadius: 10,
          paddingVertical: 6,
          paddingHorizontal: 14,
          columnGap: 5,
          alignItems: 'center',
          borderColor: theme.palettes.neutral[80]!,
          borderWidth: flat ? 1 : 0,
          backgroundColor: flat
            ? theme.colors.background
            : theme.colors.surface,
        },
        item: {
          flexDirection: 'row',
          wrap: 'nowrap',
          columnGap: 5,
          alignItems: 'center',
        },
      }),
    [theme, flat]
  )
  return (
    <Surface
      style={[styles.container, style]}
      mode={flat ? 'flat' : 'elevated'}
    >
      {Array.from({ length: level + 1 }).map((_, i) => (
        <View style={styles.item} key={i}>
          <Icon
            source={icons[i]}
            size={iconSizes[i]}
            color={
              i == level
                ? theme.colors.onBackground
                : theme.palettes.neutral[80]!
            }
          />
          {i < level ? (
            <Text
              variant={'bodyLarge'}
              style={{
                color: theme.palettes.neutral[80]!,
                fontSize: 10,
                textAlign: 'center',
              }}
            >
              \
            </Text>
          ) : null}
        </View>
      ))}
    </Surface>
  )
}
