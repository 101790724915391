import { View, StyleSheet, Image } from 'react-native'
import { useInboundOutboundContributionsQuery } from '../generated/graphql'
import { useAuthContext } from '../auth/auth-context'
import { useNavigation } from '@react-navigation/native'
import { AppNavigation } from '../navigation/types'
import { useMemo } from 'react'
import { Text, TouchableRipple } from 'react-native-paper'
import { PNGCoRankBkg } from '../utils/meldd-icons'
import { SocketEvents, useSocketRoom } from '../socket/socket.hooks'

interface CoRankButtonProps { }

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    width: 40,
    height: 40,
    borderRadius: 20,
    overflow: 'hidden',
  },
  contentWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {},
})

export function CoRankButton(props: CoRankButtonProps) {
  const { userId } = useAuthContext()
  const navigation = useNavigation<AppNavigation>()
  const {
    loading: loading,
    error,
    data: data,
    refetch
  } = useInboundOutboundContributionsQuery({ variables: { userId } })
  useSocketRoom(userId).event(SocketEvents.REFRESH, refetch)
  const corank = useMemo(() => {
    if (data?.inboundOutboundContributions) {
      return data.inboundOutboundContributions.corank
    }
    return 0
  }, [data?.inboundOutboundContributions])
  return (
    <View style={styles.container}>
      <View style={styles.contentWrapper}>
        <Image source={PNGCoRankBkg} style={[{ width: 40, height: 40 }]} />
      </View>
      <TouchableRipple
        borderless
        centered
        style={styles.contentWrapper}
        onPress={() => navigation.navigate('Profile', { userId })}
      >
        <Text variant={'labelLarge'}>{corank}</Text>
      </TouchableRipple>
    </View>
  )
}
