import { useMemo } from 'react'
import { Appbar, IconButton } from 'react-native-paper'
import { StackHeaderProps, StackNavigationProp } from '@react-navigation/stack'
import { AppNavigatorParams } from '../../navigation/types'
import { StyleSheet } from 'react-native'
import { useTheme } from '../../hooks/use-theme'
import { IconClose } from '../../utils/meldd-icons'
import { LevelIndicator } from '../../components/LevelIndicator'

export interface VisualizationNavigationBarProps extends StackHeaderProps {
  navigation: StackNavigationProp<AppNavigatorParams>
}

export function VisualizationNavigationBar({
  navigation,
  route,
  options,
  back,
}: VisualizationNavigationBarProps) {
  const theme = useTheme()
  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        backgroundColor: theme.palettes.primary[96]!,
        justifyContent: 'space-between',
        paddingHorizontal: 20,
      },
    })
  }, [theme])
  return (
    <Appbar.Header style={styles.container}>
      {back ? (
        <IconButton
          mode={'outlined'}
          icon={IconClose}
          style={{ margin: 0 }}
          onPress={navigation.goBack}
        />
      ) : null}
      <LevelIndicator level={1} style={{ backgroundColor: 'white' }} />
      {/*<Appbar.Action*/}
      {/*  icon={IconDecisions}*/}
      {/*  onPress={() => navigation.push('DecisionView')}*/}
      {/*/>*/}
    </Appbar.Header>
  )
}
