import { StyleSheet, Text, View } from 'react-native'
import Toast from 'react-native-root-toast'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import 'react-native-get-random-values'
import { AuthContext } from '../auth/auth-context'
import { StackScreenProps } from '@react-navigation/stack'
import { AuthNavigatorParams } from '../navigation/types'
import {
  SignUpInput,
  useSignUpMutation,
  useSuggestUsernameLazyQuery,
  useValidUsernameLazyQuery,
} from '../generated/graphql'
import { MessageType } from '../utils/message-type'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { Button, Divider, HelperText, TextInput } from 'react-native-paper'
import { ControlledTextInput } from '../components/form/ControlledTextInput'
import { useTheme } from '../hooks/use-theme'
import { ScreenWrapper } from '../components/ScreenWrapper'
import { ControlledMobileInput } from '../components/form/ControlledMobileInput'
import ControlledCodeInput from '../components/form/ControlledCodeInput'
import { useGlobalStyles } from '../contexts/GlobalStylesProvider'
import { Copyright } from '../components/Copyright'
import { debounce } from 'lodash'
import { useErrorTranslation } from '../utils/error-utils'
import Checkbox from 'expo-checkbox'

interface FormShape {
  firstName: string
  lastName: string
  username: string
  mobileNumber: string
  countryCode: string
  verificationCode: string
}

const initialFormState: FormShape = {
  firstName: '',
  lastName: '',
  username: '',
  mobileNumber: '',
  countryCode: '',
  verificationCode: '',
}

export function RegisterScreen({
  navigation,
  route: { params },
}: StackScreenProps<AuthNavigatorParams, 'Register'>) {
  const authCtx = useContext(AuthContext)
  const theme = useTheme()
  const globalStyles = useGlobalStyles()
  const { t } = useTranslation('login_register_reset')
  const tError = useErrorTranslation()
  const { handleSubmit, control, watch, formState, getValues, trigger } =
    useForm<FormShape>({
      defaultValues: {
        ...initialFormState,
        mobileNumber: params?.mobileNumber || '',
        countryCode: params?.mobileCountryCode || '',
      },
      mode: 'onTouched',
    })
  const { errors, isValid } = formState
  const [accessKey, setAccessKey] = useState('')
  const [isPolicyChecked, setIsPolicyChecked] = useState(false)
  const [isOTPAgreed, setIsOTPAgreed] = useState(false)
  const [mobileNumber, countryCode, firstName, lastName, username] = watch([
    'mobileNumber',
    'countryCode',
    'firstName',
    'lastName',
    'username',
  ])
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(false)

  const [doSuggestUsername, { data: suggestedUsernameData }] =
    useSuggestUsernameLazyQuery()
  const [doValidateUsername, { data: validUsernameData }] =
    useValidUsernameLazyQuery()
  const [suggestedUsername, setSuggestedUsername] = useState('')
  const isUsernameValid = useMemo(
    () => validUsernameData?.usernameAvailable.isAvailable || false,
    [validUsernameData]
  )
  const suggestUsername = useCallback(
    debounce((firstName: string, lastName: string) => {
      doSuggestUsername({ variables: { input: { firstName, lastName } } })
        .then((value) => {
          setSuggestedUsername(value.data?.suggestUsername.username || '')
        })
        .catch((err) => console.log(err))
    }, 200),
    []
  )
  useEffect(() => {
    suggestUsername(firstName || 'First', lastName || 'Last')
    return () => suggestUsername.cancel()
  }, [firstName, lastName])

  const [doSignUp, { loading }] = useSignUpMutation({
    async onCompleted({ signUp }) {
      Toast.show(t('Success'), MessageType.info)
      authCtx.setCurrentUser(signUp)
    },
    async onError(e) {
      const message = tError(e)
      return Toast.show(message, MessageType.error)
    },
  })

  function submit(data: FormShape) {
    const {
      mobileNumber,
      firstName,
      lastName,
      verificationCode,
      countryCode,
      username,
    } = data
    const input: SignUpInput = {
      firstName,
      lastName,
      mobileNumber,
      mobileCountryCode: Number(countryCode),
      smsCode: verificationCode,
      appAccessKey: accessKey,
      username: username || suggestedUsername,
    }

    // noinspection JSIgnoredPromiseFromCall
    doSignUp({ variables: { input } })
  }

  return (
    <View style={{ flex: 1 }}>
      <ScreenWrapper contentContainerStyle={globalStyles.pageContainer}>
        {/*
        <View style={{marginBottom: theme.spacing(6)}}><TextInput
          mode="outlined"
          label={t("Access Key")}
          placeholder={t("Access Key")}
          value={accessKey}
          maxLength={16}
          editable={!isAccessKeyValid}
          disabled={isAccessKeyValid}
          onChangeText={onAccessKeyChange}
        /></View>
*/}
        <ControlledTextInput
          control={control}
          name="firstName"
          // rules={{required: t('requiredFirstName')}}
          placeholder={t('First name')}
          label={t('First name')}
          mode="outlined"
          autoCapitalize="none"
          error={!!errors.firstName}
          helperText={errors.firstName?.message}
          wrapperStyle={globalStyles.row}
        />
        <ControlledTextInput
          control={control}
          name="lastName"
          // rules={{required: t('requiredLastName')}}
          placeholder={t('Last name')}
          label={t('Last name')}
          mode="outlined"
          autoCapitalize="none"
          error={!!errors.lastName}
          helperText={errors.lastName?.message}
          wrapperStyle={globalStyles.row}
        />
        <Controller
          control={control}
          name="username"
          rules={{
            validate: async (value: any) => {
              if (!value) {
                return true
              }
              try {
                const result = await doValidateUsername({
                  variables: { username: value },
                })
                if (result.error) {
                  return t(`Couldn't validate username`)
                } else if (result.data!.usernameAvailable.isAvailable) {
                  return true
                } else {
                  return t(
                    result.data!.usernameAvailable.message ||
                    'Username not available'
                  )
                }
              } catch (e) {
                return t(`Couldn't validate username`)
              }
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={globalStyles.row}>
              <TextInput
                label={t('Username')}
                mode="outlined"
                autoCapitalize="none"
                autoCorrect={false}
                autoComplete={'username'}
                textContentType={'username'}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value || suggestedUsername}
                error={!!errors.username || false}
              />
              <HelperText type="error" visible={!!errors.username}>
                {errors.username?.message}
              </HelperText>
            </View>
          )}
        />
        <ControlledMobileInput
          control={control}
          names={['countryCode', 'mobileNumber']}
          error={!!errors.mobileNumber}
          getValues={getValues}
          trigger={trigger}
          helperText={errors.mobileNumber?.message}
          wrapperStyle={globalStyles.flexNoWrap}
          onIsValid={setIsMobileNumberValid}
        ></ControlledMobileInput>

        <ControlledCodeInput
          countryCode={countryCode}
          mobileNumber={isMobileNumberValid ? mobileNumber : undefined}
          name="verificationCode"
          control={control}
          error={errors.verificationCode}
        />
        <View style={styles.linksCtn}>
          <Checkbox
            style={{ marginTop: 10 }}
            disabled={false}
            value={isPolicyChecked}
            onValueChange={setIsPolicyChecked}
          />
          <Button onPress={() => navigation.navigate('PrivacyPolicy')}>
            <Text style={{ textDecorationLine: 'underline' }}>
              {t('Privacy policy')}
            </Text>
          </Button>
        </View>
        <View style={styles.linksCtn}>
          <Checkbox
            style={{ marginBottom: 10, marginRight: 10 }}
            disabled={false}
            value={isOTPAgreed}
            onValueChange={setIsOTPAgreed}
          />
          <Text>{t('verificationOTPMessage')}</Text>
        </View>

        <View style={globalStyles.verticalSpacer} />
        <Button
          mode="elevated"
          onPress={handleSubmit(submit)}
          disabled={!isValid || loading || !isOTPAgreed || !isPolicyChecked}
        >
          {t('Sign Up')}
        </Button>
        <Divider style={{ marginTop: 25, marginHorizontal: 20 }} />
        <View style={globalStyles.verticalSpacer} />
        <Button
          mode="outlined"
          onPress={() => {
            navigation.navigate('LoginMobileNumber')
          }}
          disabled={loading}
        >
          {t('Sign In')}
        </Button>
      </ScreenWrapper>
      <Copyright />
    </View>
  )
}

const styles = StyleSheet.create({
  linksCtn: {
    flexWrap: 'nowrap',
    flexDirection: 'row',
    alignContent: 'stretch',
    flexGrow: 1,
    alignItems: 'center',
    marginVertical: 10,
  },
})
