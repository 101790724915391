import {
  StyleSheet,
  Text,
  TextInput as TextInputNative,
  View,
} from 'react-native'
import { Button, Divider } from 'react-native-paper'
import Toast from 'react-native-root-toast'
import React, { useContext, useEffect, useRef, useState } from 'react'
import 'react-native-get-random-values'
import Checkbox from 'expo-checkbox'

import { AuthContext } from '../../auth/auth-context'
import { StackScreenProps } from '@react-navigation/stack'
import { AuthNavigatorParams } from '../../navigation/types'
import { useLoginMobileOtpMutation } from '../../generated/graphql'
import { MessageType } from '../../utils/message-type'
import { useIsFocused } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { useForm } from 'react-hook-form'
import { useTheme } from '../../hooks/use-theme'
import { Copyright } from '../../components/Copyright'
import ControlledCodeInput from '../../components/form/ControlledCodeInput'
import { ControlledMobileInput } from '../../components/form/ControlledMobileInput'
import { useGlobalStyles } from '../../contexts/GlobalStylesProvider'
import { getErrorCode, useErrorTranslation } from '../../utils/error-utils'
import { useModalDialog } from '../../components/ModalDialogProvider'
import { LocalizationUtils } from '../../utils/localization'
import GoogleLogin from './GoogleLogin'
import { Platform } from 'react-native'
interface FormShape {
  mobileNumber: string
  mobileCountryCode: string
  otp: string
}

export function LoginMobileNumberScreen({
  navigation,
  route,
}: StackScreenProps<AuthNavigatorParams, 'LoginMobileNumber'>) {
  const { setCurrentUser, lastUserData } = useContext(AuthContext)
  const [googleTokenId, setGoogleTokenId] = useState('')
  const { setDialog } = useModalDialog()
  const theme = useTheme()
  const globalStyles = useGlobalStyles()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isSubmitting },
    watch,
    getValues,
    trigger,
  } = useForm<FormShape>({
    defaultValues: async () => {
      const countryCode =
        lastUserData.mobileCountryCode ||
        (await LocalizationUtils.get().countryCode())
      return {
        mobileNumber: lastUserData.mobileNumber || '',
        mobileCountryCode: `${countryCode}`,
        otp: '',
      }
    },
    mode: 'onTouched',
  })
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(false)
  const [mobileNumber, mobileCountryCode] = watch([
    'mobileNumber',
    'mobileCountryCode',
  ])

  const [isPolicyChecked, setIsPolicyChecked] = useState<boolean>(false)
  const [isOTPAgreed, setIsOTPAgreed] = useState<boolean>(false)

  const isFocused = useIsFocused()
  const otpRef = useRef<TextInputNative>(null)
  const [doLoginUser, { client, loading }] = useLoginMobileOtpMutation()
  const { t } = useTranslation('login_register_reset')
  const tError = useErrorTranslation()

  useEffect(() => {
    if (isFocused && otpRef.current) {
      otpRef.current.focus()
    }
  }, [isFocused, otpRef])

  const submit = async (data: FormShape) => {
    const { mobileNumber, mobileCountryCode, otp } = data

    try {
      const result = await doLoginUser({
        variables: {
          input: {
            mobileNumber,
            mobileCountryCode: parseInt(mobileCountryCode || '0'),
            otp,
            // If present will link the account to this google account
            googleToken: googleTokenId
              ? { token: googleTokenId, os: Platform.OS }
              : undefined,
          },
        },
      })
      await client.clearStore()
      setCurrentUser(result.data!.loginMobileOTP)
      Toast.show(t('Login successful'), MessageType.info)
    } catch (e) {
      console.error(e)
      Toast.show(tError(e), MessageType.error)
    }
  }

  const styles = StyleSheet.create({
    container: {
      padding: theme.components.pageContainer.padding,
      paddingTop: theme.spacing(10),
    },
    row: {
      marginVertical: theme.spacing(0),
    },
    flex: {
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'center',
    },
    notRegistered: {
      flexDirection: 'row',
      paddingTop: theme.spacing(10),
      alignContent: 'center',
      justifyContent: 'center',
    },
    linksCtn: {
      flexWrap: 'nowrap',
      flexDirection: 'row',
      alignContent: 'stretch',
      flexGrow: 1,
      alignItems: 'center',
      marginVertical: 5,
    },
    divider: {
      marginTop: 25,
      marginHorizontal: 20,
    },
    registerCtn: {
      marginTop: 25,
      flexWrap: 'nowrap',
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: 'center',
    },
    googleSyncInfoBox: {
      backgroundColor: theme.colors.secondaryContainer, // Assuming you have a color in your theme
      padding: theme.spacing(2),
      borderRadius: 8,
      marginVertical: theme.spacing(2),
    },
    googleSyncInfoText: {
      color: theme.colors.text, // Assuming you have a text color in your theme
    },
  })

  const handlePolicyCheck = (isChecked: boolean) => {
    setIsPolicyChecked(isChecked)
  }

  return (
    <View style={{ flex: 1 }}>
      <ScreenWrapper>
        <View style={styles.container}>
          <ControlledMobileInput
            control={control}
            names={['mobileCountryCode', 'mobileNumber']}
            error={!!errors.mobileNumber}
            getValues={getValues}
            trigger={trigger}
            helperText={errors.mobileNumber?.message}
            wrapperStyle={globalStyles.flexNoWrap}
            onIsValid={setIsMobileNumberValid}></ControlledMobileInput>
          <ControlledCodeInput
            isOTP={true}
            mobileNumber={mobileNumber}
            countryCode={mobileCountryCode}
            name="otp"
            control={control}
            error={errors.otp}
            errorHandler={(e) => {
              const errorCode = getErrorCode(e)
              console.log(`errorCode: ${errorCode}`)
              if (errorCode === 'NO_SUCH_USER') {
                setDialog({
                  title: t('No such user'),
                  body: (
                    <Text>{t('Do you want to register a new account?')}</Text>
                  ),
                  primaryLabel: t('Sign Up'),
                  primaryAction: async () => {
                    setDialog(null)
                    navigation.navigate('Register', {
                      mobileCountryCode,
                      mobileNumber,
                    })
                  },
                  secondaryAction: () => {
                    setDialog(null)
                  },
                  secondaryLabel: t('Retry'),
                })
                return true
              }
              return false
            }}
          />
          <View style={styles.linksCtn}>
            <Checkbox
              disabled={false}
              value={isPolicyChecked}
              onValueChange={(newValue: boolean) => handlePolicyCheck(newValue)}
            />
            <Button onPress={() => navigation.navigate('PrivacyPolicy')}>
              <Text style={{ textDecorationLine: 'underline' }}>
                {t('Privacy policy')}
              </Text>
            </Button>
          </View>
          <View style={styles.linksCtn}>
            <Checkbox
              style={{ marginBottom: 0, marginRight: 10 }}
              disabled={false}
              value={isOTPAgreed}
              onValueChange={(newValue: boolean) =>
                setIsOTPAgreed(!isOTPAgreed)
              }
            />
            <Text>{t('verificationOTPMessage')}</Text>
          </View>
          {googleTokenId &&
            <View style={styles.googleSyncInfoBox}>
              <Text style={styles.googleSyncInfoText}>
                {t('sing-google-sync-info')}
              </Text>
            </View>}
          <View style={styles.linksCtn}>
            <Button
              style={{ flex: 1 }}
              mode={'contained'}
              onPress={handleSubmit(submit)}
              disabled={
                !isMobileNumberValid ||
                !isValid ||
                isSubmitting ||
                !isPolicyChecked ||
                !isOTPAgreed
              }>
              <Text>   {googleTokenId ? t('sing-google-sync') : t('Sign In')}</Text>
            </Button>
          </View>
          <View style={styles.registerCtn}>
            <GoogleLogin disabled={isSubmitting} onMergeAccount={setGoogleTokenId} />
          </View>
          <Divider style={styles.divider} />
          <View style={styles.registerCtn}>
            <Button
              style={{ flex: 1 }}
              mode={'outlined'}
              onPress={() =>
                navigation.navigate('Register', {
                  mobileCountryCode,
                  mobileNumber,
                })
              }
              disabled={loading}>
              {t('Sign Up')}
            </Button>
          </View>
        </View>
      </ScreenWrapper>
      <Copyright />
    </View>
  )
}
