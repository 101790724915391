import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Dimensions, StyleSheet, View } from 'react-native'
import Animated, {
  runOnJS,
  SharedValue,
  useAnimatedReaction,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'

const mod = (dividend: number, divisor: number) => {
  'worklet'
  return ((dividend % divisor) + divisor) % divisor
}

const width = Dimensions.get('window').width
const boxOverflow = 30
const boxMargin = 10
const boxWidth = width - 2 * boxOverflow
const height = Dimensions.get('window').height
const styles = StyleSheet.create({
  box: {
    // top: 0,
    position: 'absolute',
    padding: boxMargin,
    // height: width / 3,
    width: boxWidth,
    alignItems: 'stretch',
    justifyContent: 'center',
    height: '100%',
  },
})
const logger = (...args: any[]) => console.log(...args)

interface SwipeableCardsProps {
  children: React.ReactNode | React.ReactNode[]
  initialSelectedIndex: number
  onSelectedIxChange?: (ix: number) => void
  moveToCardAction?: (select: (index: number) => void) => void // Hook that allows to select the index from the outisde
}

const bIndices = [0, 1, 2, 3, 4]

const SLOTS_COUNT = 5

export function SwipeableCards(props: SwipeableCardsProps) {
  const [wrappedChildren, count] = useMemo(() => {
    // logger('wrapping children')
    return [
      Array.isArray(props.children)
        ? [
          <View></View>,
          ...props.children,
          <View></View>,
          <View></View>,
          <View></View>,
        ]
        : [
          <View></View>,
          props.children,
          <View></View>,
          <View></View>,
          <View></View>,
        ],
      Array.isArray(props.children) ? props.children.length + 4 : SLOTS_COUNT,
    ]
  }, [props.children])

  const xOffset = useSharedValue(-props.initialSelectedIndex * boxWidth)

  // Calculate the indices of the boxes that should be visible - which slot should show which child
  const calculateSlotContent = (
    xOffsetValue: number,
    baseIndexValue: number
  ) => {
    'worklet'
    // runOnJS(logger)(`calculating slot content for ${xOffsetValue} ${baseIndexValue}`)
    return bIndices.map((i) => {
      const absPos =
        mod(i * boxWidth + xOffsetValue + boxOverflow, SLOTS_COUNT * boxWidth) -
        boxOverflow
      const index = Math.round(absPos / boxWidth)
      return mod(baseIndexValue + index - 1, count)
    })
  }

  const startX = useSharedValue(0)

  // holds first index of our children should be shown in a slot
  const baseIndex: SharedValue<number> = useDerivedValue(() =>
    mod(Math.round(-xOffset.value / boxWidth), count)
  )
  useEffect(() => {
    xOffset.value = -props.initialSelectedIndex * boxWidth
  }, [])
  // On init, we send a function that the parent can fetch with a ref and manually move the swipper scroll
  useEffect(() => {
    props.moveToCardAction?.((index) => {
      (xOffset.value = -index * boxWidth);
    })
  }, [props.moveToCardAction])
  const [show, setShow] = useState(
    calculateSlotContent(xOffset.value, props.initialSelectedIndex)
  )

  const notifySelectedIxChange = useCallback(
    (ix: number) => {
      console.log('notifySelectedIxChange: ' + ix)
      props.onSelectedIxChange?.(ix)
    },
    [props.onSelectedIxChange]
  )
  // calculate the selected index from xOffset
  useAnimatedReaction(
    () => {
      return Math.round(-xOffset.value / boxWidth)
    },
    (ix, prev) => {
      if (ix != prev) {
        runOnJS(notifySelectedIxChange)(ix)
      }
    }
  )

  useAnimatedReaction(
    () => {
      const numbers = calculateSlotContent(xOffset.value, baseIndex.value)
      return numbers
    },
    (n, prev) => {
      if (
        !prev ||
        prev[0] != n[0] ||
        prev[1] != n[1] ||
        prev[2] != n[2] ||
        prev[3] != n[3] ||
        prev[4] != n[4]
      ) {
        // runOnJS(logger)(`updating slots ${JSON.stringify(n)}`)
        runOnJS(setShow)(n)
      }
    }
  )

  // Update the position of the boxes
  const animatedBoxStyles = bIndices.map((i) =>
    useAnimatedStyle(() => {
      const absPos =
        mod(
          i * boxWidth + xOffset.value + boxOverflow,
          SLOTS_COUNT * boxWidth
        ) - boxOverflow
      const pos = absPos - 2 * boxWidth + boxOverflow
      // runOnJS(logger)(`animatedBoxStyles ${i} ${pos}`)
      return {
        transform: [
          {
            translateX: pos,
          },
        ],
      }
    })
  )
  const swipeGesture = Gesture.Pan()
    .onStart((evt) => {
      startX.value = xOffset.value
    })
    .onChange((evt) => {
      let value = startX.value + evt.translationX
      if (value > 0) {
        value = Math.atan((value / width) * 0.5) * width * 0.5
      } else if (value < -boxWidth * (count - SLOTS_COUNT)) {
        const diff = value + boxWidth * (count - SLOTS_COUNT)
        value =
          -boxWidth * (count - SLOTS_COUNT) +
          Math.atan((diff / width) * 0.5) * width * 0.5
      }
      xOffset.value = value
    })
    .onEnd((evt) => {
      const dist = width / 20

      const indexChange =
        evt.translationX > dist ? -1 : evt.translationX < -dist ? 1 : 0
      let targetX =
        (Math.round(startX.value / boxWidth) - indexChange) * boxWidth
      targetX = Math.min(
        0,
        Math.max(-boxWidth * (count - SLOTS_COUNT), targetX)
      )
      xOffset.value = withSpring(targetX, {
        damping: 80,
        stiffness: 90,
        velocity: evt.velocityX * 2,
      })
    })
    .maxPointers(1)
    .failOffsetY(30)
    .activeOffsetX([-20, 20])

  const boxes = useMemo(
    () =>
      bIndices.map((i) => {
        return (
          <Animated.View key={i} style={[styles.box, animatedBoxStyles[i]]}>
            {wrappedChildren[show[i]]}
          </Animated.View>
        )
      }),
    [show, wrappedChildren]
  )

  return (
    <GestureDetector gesture={swipeGesture}>
      <Animated.View style={{ flex: 1, position: 'relative' }}>
        <Animated.View
          /*
                    entering={FadeInUp.duration(zoomDuration)} exiting={FadeOutUp.duration(zoomDuration)}
          */
          style={{
            position: 'relative',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            alignItems: 'stretch',
            flex: 1,
          }}
        >
          {boxes}
        </Animated.View>
      </Animated.View>
    </GestureDetector>
  )
}
