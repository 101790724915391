import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { Pressable, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { Button, IconButton, Text } from 'react-native-paper'
import { useTheme } from '../../hooks/use-theme'
import {
  usePlaceVoteMutation,
  useRemoveVoteMutation,
  VoteDetailFragmentDoc,
} from '../../generated/graphql'
import {
  Icon3dVote,
  IconVote,
} from '../../utils/meldd-icons'
import { useAuthContext } from '../../auth/auth-context'
import Toast from 'react-native-root-toast'
import { useTranslation } from 'react-i18next'
import {
  CANCEL_VOTE,
  useJustificationProvider,
} from '../../contexts/JustificationProvider'
import { Icon } from '../Icon'
import { useVoteDetailsProvider } from './VoteDetailsProvider'
import { ArticleVote } from './types'

export type VoteButtonCluster = { id: string; articleVotes: ArticleVote[] }
export type VoteButtonPerspective = { id: string; title: string }

interface VoteButtonProps {
  cluster: VoteButtonCluster
  perspective: VoteButtonPerspective
  mode?: 'horizontal' | 'vertical'
  style?: StyleProp<ViewStyle>
}

export function VoteButton({
  cluster,
  perspective,
  mode = 'horizontal',
  style,
}: VoteButtonProps) {
  const { t } = useTranslation('perspective')
  const theme = useTheme()
  const { userId } = useAuthContext()
  const showJustificationModal = useJustificationProvider()
  const doShowVoteDetails = useVoteDetailsProvider()
  const showVoteDetails = useCallback(() => {
    doShowVoteDetails(
      cluster.articleVotes.filter((v) => v.articleId === perspective.id)
    )
  }, [doShowVoteDetails, cluster.articleVotes, perspective])
  const voteCount = useMemo(
    () =>
      cluster.articleVotes.filter((v) => v.articleId === perspective.id).length,
    [cluster, perspective]
  )
  const hasMyVote = useMemo(
    () =>
      cluster.articleVotes.some(
        (v) => v.articleId === perspective.id && v.userId === userId
      ),
    [cluster, perspective]
  )
  const [doPlaceVote, { loading: placingVote }] = usePlaceVoteMutation({
    update: (cache, result) => {
      if (result.data) {
        const { placeArticleVote } = result.data
        cache.modify({
          id: 'Cluster:' + cluster.id,
          fields: {
            articleVotes: () => {
              return placeArticleVote.map((v) =>
                cache.writeFragment({
                  data: v,
                  fragment: VoteDetailFragmentDoc,
                })
              )
            },
          },
        })
      }
    },
  })
  const [doRemoveVote, { loading: removingVote }] = useRemoveVoteMutation({
    update: (cache, result) => {
      if (result.data) {
        const { removeArticleVote } = result.data
        cache.modify({
          id: 'Cluster:' + cluster.id,
          fields: {
            articleVotes: () => {
              return removeArticleVote.map((v) =>
                cache.writeFragment({
                  data: v,
                  fragment: VoteDetailFragmentDoc,
                })
              )
            },
          },
        })
      }
    },
  })
  const removeVote = useCallback(async () => {
    try {
      const result = await doRemoveVote({
        variables: {
          input: {
            articleId: perspective.id,
            clusterId: cluster.id,
          },
        },
      })
      Toast.show(t('Vote removed'))
    } catch (e) {
      Toast.show((e as Error).message)
    }
  }, [perspective, cluster])
  const placeVote = useCallback(
    async (justification: string | typeof CANCEL_VOTE) => {
      if (justification === CANCEL_VOTE) {
        showJustificationModal(null)
        return
      }
      try {
        const result = await doPlaceVote({
          variables: {
            input: {
              articleId: perspective.id,
              clusterId: cluster.id,
              justification: justification.trim().length
                ? justification.trim()
                : null,
            },
          },
        })
        Toast.show(t('Vote placed'))
      } catch (e) {
        Toast.show((e as Error).message)
      }
      showJustificationModal(null)
    },
    [perspective, cluster]
  )
  const askForJustification = useCallback(() => {
    showJustificationModal(placeVote, perspective.title)
  }, [perspective])

  const styles = useMemo(
    () =>
      StyleSheet.create({
        vertical: {
          position: 'relative',
          alignItems: 'center',
          color: theme.colors.primary,
          rowGap: theme.spacing(0),
        },
        horizontal: {
          position: 'relative',
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: theme.spacing(2),
        },
        horizontalText: {
          position: 'relative',
        },
        verticalText: {
          position: 'relative',
          borderColor: 'rgba(239, 239, 240, 1)',
          backgroundColor: 'rgba(239, 239, 240, 0.7)',
          height: 35,
          width: 35,
          borderRadius: 100,
          justifyContent: 'center',
        },
      }),
    [theme]
  )

  return mode == 'vertical' ? (
    <View style={[styles.vertical, style]}>
      <IconVote
        size={30}
        disabled={placingVote || removingVote}
        onPress={() => (hasMyVote ? removeVote() : askForJustification())}
        selected={hasMyVote}

      />
      <Pressable style={[styles.verticalText]} onPress={showVoteDetails}>
        <Text
          style={{
            textAlign: 'center',
            color: hasMyVote
              ? theme.colors.primary
              : theme.palettes.primary[80]!,
          }}
        >
          {voteCount}
        </Text>
      </Pressable>
    </View>
  ) : (
    <View style={[styles.horizontal, style]}>
      <View style={[styles.horizontalText]}>
        <Text
          style={{
            color: theme.colors.primary,
            textDecorationLine: 'underline',
          }}
        >
          {t('Votes')}: {voteCount}
        </Text>
        <Pressable
          style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
          onPress={showVoteDetails}
        />
      </View>
      <View style={{ position: 'relative' }}>
        <Icon3dVote
          selected={hasMyVote} size={48}
          disabled={placingVote || removingVote}
          onPress={() => (hasMyVote ? removeVote() : askForJustification())}
        />
      </View>
    </View>
  )
}
