import * as React from 'react'
import { Chip, Menu } from 'react-native-paper'
import { MdNode } from '../screens/ListNodes/types'
import { useCallback, useMemo } from 'react'
import { Text } from 'react-native'
import { IconNode } from '../utils/meldd-icons'
interface NodeSelectorProps {
  selected?: MdNode
  nodes: MdNode[]
  onSelect: (node: MdNode) => void
}

export default function NodeSelector(props: NodeSelectorProps) {
  const [visible, setVisible] = React.useState(false)
  const onSelection = useCallback(
    (node: MdNode) => {
      setVisible(false), props.onSelect(node)
    },
    [props.onSelect]
  )
  const menuItems = useMemo(
    () =>
      props.nodes.map((node) => (
        <Menu.Item
          key={node.id}
          leadingIcon={IconNode}
          onPress={() => onSelection(node)}
          title={node.name}
        />
      )),
    [props.nodes]
  )
  return (
    <Menu
      visible={visible}
      onDismiss={() => setVisible(false)}
      anchorPosition={'bottom'}
      anchor={
        <Chip
          icon={IconNode}
          mode="outlined"
          closeIcon={visible ? 'menu-up' : 'menu-down'}
          onPress={() => setVisible((prev) => !prev)}
          onClose={() => setVisible((prev) => !prev)}
        >
          <Text>{props.selected?.name || ''}</Text>
        </Chip>
      }
    >
      {menuItems}
    </Menu>
  )
}
