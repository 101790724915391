import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useReducer,
  useMemo,
} from 'react'
import { IconSource } from 'react-native-paper/lib/module/components/Icon'

type Handler = () => void | Promise<void>
type HeaderButtonConfig<T> = {
  icon: IconSource | null
  handler: Handler | null
  disabled: boolean
  meta?: T
}

type HeaderButtonContextType<T> = {
  config: Record<string, HeaderButtonConfig<T>>
  setIcon: (target: string, icon: IconSource | null) => void
  setHandler: (target: string, handler: Handler | null) => void
  setDisabled: (target: string, value: boolean) => void
  setMeta: (target: string, value: T) => void
}

const initialConfig: Record<string, HeaderButtonConfig<any>> = {}
export const HeaderButtonContext = createContext<HeaderButtonContextType<any>>({
  config: {},
  setHandler: () => {},
  setIcon: () => {},
  setDisabled: () => {},
  setMeta: () => {},
})

type HeaderButtonProviderProps = {
  children: ReactNode
}
type Action<T> =
  | { type: 'icon'; target: string; icon: IconSource | null }
  | { type: 'handler'; target: string; handler: Handler | null }
  | { type: 'disabled'; target: string; disabled: boolean }
  | { type: 'meta'; target: string; meta: T }
const reducer = (
  state: Record<string, HeaderButtonConfig<any>>,
  action: Action<any>
) => {
  const { target } = action
  let newState = { ...state }
  let newTarget = state[target] || { icon: null, handler: null, disabled: true }
  switch (action.type) {
    case 'icon':
      newTarget = { ...newTarget, icon: action.icon }
      break
    case 'handler':
      newTarget = { ...newTarget, handler: action.handler }
      break
    case 'disabled':
      newTarget = { ...newTarget, disabled: action.disabled }
      break
    case 'meta':
      newTarget = { ...newTarget, meta: action.meta }
      break
  }
  return { ...newState, [target]: newTarget }
}
/**
 * A utility to provide a button to the header that can be configured from the page
 */
export const HeaderButtonProvider = ({
  children,
}: HeaderButtonProviderProps) => {
  const [config, dispatch] = useReducer(reducer, initialConfig)

  const value = useMemo(
    () => ({
      config,
      setIcon: (target: string, icon: IconSource | null) =>
        dispatch({ type: 'icon', icon, target }),
      setDisabled: (target: string, value: boolean) =>
        dispatch({ type: 'disabled', disabled: value, target }),
      setHandler: (target: string, handler: Handler | null) =>
        dispatch({ type: 'handler', handler, target }),
      setMeta: <T,>(target: string, meta: T | null) =>
        dispatch({ type: 'meta', meta, target }),
    }),
    [config, dispatch]
  )
  return (
    <HeaderButtonContext.Provider value={value}>
      {children}
    </HeaderButtonContext.Provider>
  )
}

export function useHeaderButton<T = any>(target: string) {
  const { setHandler, setDisabled, setIcon, config, setMeta } =
    useContext(HeaderButtonContext)
  return {
    setHandler: (handler: Handler | null) => setHandler(target, handler),
    setDisabled: (value: boolean) => setDisabled(target, value),
    setIcon: (icon: IconSource | null) => setIcon(target, icon),
    setMeta: <T,>(meta: T) => setMeta(target, meta),
    icon: config[target]?.icon,
    handler: config[target]?.handler,
    disabled: config[target]?.disabled,
    meta: config[target]?.meta as T,
  }
}
