import React, { createContext, ReactNode, useContext, useMemo } from 'react'
import { StyleSheet } from 'react-native'
import { Button, Dialog, Portal } from 'react-native-paper'
import { useTheme } from '../hooks/use-theme'

type ModalDialogParams = {
  title: string
  body: ReactNode
  primaryAction: () => void
  primaryLabel: string
  secondaryAction?: () => void
  secondaryLabel?: string
  closeAction?: () => void
  bkgColor?: string
  accentColor?: string
  onAccentColor?: string
}
type ModalDialogProviderContextShape = {
  setDialog: (options: ModalDialogParams | null) => void
}

const INITIAL_VALUE: ModalDialogProviderContextShape = {
  setDialog: () => {},
}
export const ModalDialogProviderContext =
  createContext<ModalDialogProviderContextShape>(INITIAL_VALUE)

type ModalDialogProviderProps = {
  children: ReactNode
}

export const ModalDialogProvider = ({ children }: ModalDialogProviderProps) => {
  const [dialogParams, setDialogParams] =
    React.useState<ModalDialogParams | null>(null)
  const theme = useTheme()
  const value = React.useMemo(() => {
    return {
      setDialog: setDialogParams,
    }
  }, [setDialogParams])
  const styles = useMemo(
    () =>
      StyleSheet.create({
        dialog: {
          backgroundColor: theme.colors.secondaryContainer,
        },
        title: {},
        content: {},
        button: {
          paddingHorizontal: theme.spacing(4),
        },
      }),
    [theme]
  )

  return (
    <ModalDialogProviderContext.Provider value={value}>
      <>
        {children}
        <Portal>
          <Dialog
            style={[
              styles.dialog,
              dialogParams?.bkgColor
                ? { backgroundColor: dialogParams?.bkgColor }
                : {},
            ]}
            visible={!!dialogParams}
            onDismiss={dialogParams?.closeAction}
          >
            <Dialog.Title style={styles.title}>
              {dialogParams?.title}
            </Dialog.Title>
            <Dialog.Content style={styles.content}>
              {dialogParams?.body}
            </Dialog.Content>
            <Dialog.Actions>
              {dialogParams?.secondaryAction && (
                <Button
                  style={styles.button}
                  textColor={dialogParams?.accentColor || theme.colors.primary}
                  onPress={dialogParams?.secondaryAction}
                >
                  {dialogParams?.secondaryLabel}
                </Button>
              )}
              <Button
                buttonColor={dialogParams?.accentColor || theme.colors.primary}
                textColor={dialogParams?.onAccentColor || 'white'}
                style={styles.button}
                onPress={dialogParams?.primaryAction}
              >
                {dialogParams?.primaryLabel}
              </Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
      </>
    </ModalDialogProviderContext.Provider>
  )
}

export const useModalDialog = () => {
  return useContext(ModalDialogProviderContext)
}
