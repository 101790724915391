import * as React from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import { SwipeableCards } from '../../components/SwipeableCards'
import { StyleSheet, View } from 'react-native'
import { IconButton } from 'react-native-paper'
import { useCurrentNode } from '../../contexts/CurrentNodeProvider'
import { useAvailableClustersLazyQuery } from '../../generated/graphql'
import { ClusterCard } from './components/ClusterCard'
import { AppNavigatorParams } from '../../navigation/types'
import { StackScreenProps } from '@react-navigation/stack'
import { orderBy } from 'lodash'
import { useTheme } from '../../hooks/use-theme'
import { LevelIndicator } from '../../components/LevelIndicator'
import { useHeaderButton } from '../../contexts/HeaderButtonProvider'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { useGlobalStyles } from '../../contexts/GlobalStylesProvider'
import { SocketEvents, useSocketRoom } from '../../socket/socket.hooks'

export function ClusterCardsScreen({
  navigation,
  route,
}: StackScreenProps<AppNavigatorParams, 'ClusterCards'>) {
  const { params } = route
  const clusterId = params?.clusterId
  const {
    currentNode,
  } = useCurrentNode(params?.external ? params?.nodeId : undefined)
  const globalStyles = useGlobalStyles()
  const { setHandler } = useHeaderButton('visualizationCluster')
  const theme = useTheme()
  // Is a function provide for the swipper to move to a card programmatically
  const moveToCard = React.useRef<{ (card: number): void } | undefined>(undefined)
  const [doLoadClusters, { data, loading, error }] =
    useAvailableClustersLazyQuery()

  const clusters = useMemo(
    () =>
      orderBy(data?.availableClusters || [], ['contentUpdatedAt'], ['desc']),
    [data]
  )

  const styles = StyleSheet.create({
    levelIndicator: {
      // width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(4),
    },
  })
  useEffect(() => {
    if (
      (currentNode && !params?.nodeId) ||
      (currentNode && !params.external && currentNode.id !== params.nodeId)
    ) {
      navigation.setParams({
        clusterId,
        nodeId: currentNode.id,
        external: undefined,
      })
    } else if (params?.external) {
      navigation.setParams({
        clusterId,
        nodeId: params.nodeId,
        external: undefined,
      })
    }
  }, [currentNode, params])

  useEffect(() => {
    return () => setHandler(null)
  }, [])

  const doRefresh = React.useCallback(() => {
    if (currentNode) {
      doLoadClusters({
        variables: { nodeId: currentNode.id },
        fetchPolicy: 'cache-first',
      })
    }
  }, [currentNode])

  useSocketRoom(currentNode?.id).event(SocketEvents.REFRESH, doRefresh)

  const clusterCards = useMemo(() => {
    return clusters.map((c) => (
      <ClusterCard
        key={c.id}
        cluster={c}
        onRefresh={doRefresh}
        refreshing={loading}
      />
    ))
  }, [clusters, loading, doRefresh])

  const selectedIndex = useMemo(() => {
    const v = clusters.findIndex((c) => c.id === clusterId)
    if (v < 0 && clusters.length > 0) {
      return 0
    }
    return v
  }, [clusters, clusterId])
  useEffect(() => {
    doRefresh()
  }, [currentNode])

  const updateVisualizationMenu = useCallback(
    (ix: number) => {
      const clusterId = clusters[ix]?.id || clusters[0]?.id
      if (clusterId) {
        setHandler(() => navigation.navigate('Visualization', { clusterId }))
      } else {
        setHandler(null)
      }
      navigation.setParams({
        clusterId,
        nodeId: params.nodeId,
        external: undefined,
      })
    },
    [clusters]
  )

  // If the data changes (some cluster updated), make sure the user keeps seeing the right cluster when they re-order
  useEffect(() => {
    if (selectedIndex < 0) {
      return
    }
    moveToCard.current?.(selectedIndex)
  }, [data])

  return (
    <ScreenWrapper withScrollView={false} style={globalStyles.pageContainer}>
      <View style={styles.levelIndicator}>
        <IconButton
          mode={'outlined'}
          icon="arrow-left"
          onPress={() => navigation.goBack()}
        />
        <LevelIndicator level={1} flat />
      </View>
      {clusterCards.length > 0 && (
        <SwipeableCards
          initialSelectedIndex={selectedIndex}
          onSelectedIxChange={updateVisualizationMenu}
          moveToCardAction={action => moveToCard.current = action}
        >
          {clusterCards}
        </SwipeableCards>
      )}
    </ScreenWrapper>
  )
}
export default ClusterCardsScreen
