import * as React from 'react'
import { useMemo, useState } from 'react'
import {
  RequestMobileVerificationInput,
  RequestOtpInput,
  useRequestMobileVerificationCodeMutation,
  useRequestOtpMutation,
} from '../../generated/graphql'
import { MessageType } from '../../utils/message-type'
import { useTheme } from '../../hooks/use-theme'
import { useTranslation } from 'react-i18next'
import Toast from 'react-native-root-toast'
import { View } from 'react-native'
import { ControlledTextInput } from './ControlledTextInput'
import { Button } from 'react-native-paper'
import { useGlobalStyles } from '../../contexts/GlobalStylesProvider'
import { useErrorTranslation } from '../../utils/error-utils'
import { GraphQLError } from 'graphql'

interface ControlledCodeInputProps {
  isOTP?: boolean
  countryCode?: string
  mobileNumber?: string
  username?: string
  control: any
  error?: any
  name: string
  errorHandler?: (error: any) => boolean
}

export default function ControlledCodeInput(props: ControlledCodeInputProps) {
  const {
    countryCode,
    mobileNumber,
    control,
    error,
    name,
    username,
    isOTP,
    errorHandler,
  } = props
  const { t } = useTranslation('login_register_reset')
  const tError = useErrorTranslation()

  const theme = useTheme()
  const styles = useGlobalStyles()
  const [isSmsCodeSent, setIsSmsCodeSent] = useState(false)
  const [counterRequestCode, setCounterRequestCode] = useState(0)
  const [doRequestMobileVerificationCode, { loading: codeLoadingNonExisting }] =
    useRequestMobileVerificationCodeMutation()
  const [doRequestOTP, { loading: codeLoadingExisting }] =
    useRequestOtpMutation()
  const codeLoading = useMemo(
    () => codeLoadingNonExisting || codeLoadingExisting,
    [codeLoadingNonExisting, codeLoadingExisting]
  )
  const isInputValid = useMemo(
    () => username?.trim() || mobileNumber?.match(/^[0-9]+$/),
    [username, mobileNumber, countryCode]
  )
  let intervalRequestCode: ReturnType<typeof setInterval>

  function startCountdown() {
    Toast.show(
      t('Please insert the code received on your mobile.'),
      MessageType.info
    )
    setIsSmsCodeSent(true)
    setCounterRequestCode(30)
    intervalRequestCode = setInterval(() => {
      setCounterRequestCode((prev) => {
        const newCounter = prev >= 0 ? prev - 1 : 0
        if (newCounter === 0) {
          clearInterval(intervalRequestCode)
        }
        return newCounter
      })
    }, 1000)
  }

  async function handleErrors(error: GraphQLError) {
    console.error(error)
    if (errorHandler) {
      const handled = errorHandler(error)
      if (handled) {
        return
      }
    }

    const message = tError(error)

    Toast.show(
      message,
      MessageType.error
    )
  }

  async function requestCode() {
    //Validate mobile
    if (mobileNumber && mobileNumber.match(/^[0-9]+$/) == null) {
      return Toast.show(
        t(
          'Error, phone number field should contain only numbers, not spaces or characters'
        ),
        MessageType.error
      )
    }

    try {
      /**
       * OTP + Username
       */
      if (isOTP && username) {
        await doRequestOTP({
          variables: { input: { username } },
        })
        startCountdown()
        return
      }

      /**
       * Registration SMS
       */
      if (!isOTP) {
        await doRequestMobileVerificationCode({
          variables: {
            input: {
              mobileCountryCode: parseInt(countryCode || '0'),
              mobileNumber: mobileNumber!
            }
          },
        })
        startCountdown()
        return
      }

      /**
       * OTP + Mobile
       */
      const result = await doRequestOTP({
        variables: {
          input: {
            mobileCountryCode: parseInt(countryCode || '0'),
            mobileNumber: mobileNumber!,
          }
        },
      })

      startCountdown()
    } catch (e) {
      handleErrors(e as GraphQLError)
    }
  }

  return (
    <View style={styles.flexNoWrap}>
      <ControlledTextInput
        disabled={!isInputValid}
        control={control}
        name={name}
        rules={{
          required: t('requiredCode'),
          maxLength: { value: 6, message: t('invalidCode') },
          minLength: { value: 6, message: t('invalidCode') },
        }}
        placeholder={t('Code')}
        label={t('Code')}
        mode="outlined"
        autoComplete={'off'}
        autoCapitalize="none"
        error={!!error}
        helperText={error?.message}
        wrapperStyle={[
          styles.row,
          { flexGrow: 1, flex: 1, marginRight: theme.spacing(2) },
        ]}
      />
      <View style={[styles.formButtonWrapper, { flex: 1 }]}>
        <Button
          contentStyle={{ height: 52 }}
          mode="elevated"
          icon={counterRequestCode !== 0 ? 'clock-time-four-outline' : 'email'}
          onPress={requestCode}
          disabled={codeLoading || !isInputValid || counterRequestCode !== 0}>
          {counterRequestCode === 0
            ? t('Request Code')
            : `${counterRequestCode}s`}
        </Button>
      </View>
    </View>
  )
}
