import { Dimensions, StyleSheet, View } from 'react-native'
import { IconButton, Text } from 'react-native-paper'
import Toast from 'react-native-root-toast'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import 'react-native-get-random-values'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { AppNavigatorParams } from '../../navigation/types'
import {
  ArticleQuery,
  ContributionEditDetailFragmentDoc,
  ContributionStatus,
  ContributionType,
  ReserveContributionInput,
  SubmitContributionInput,
  UpdateContributionInput,
  useArticleQuery,
  useAvailableClustersQuery,
  useReserveContributionMutation,
  useSubmitContributionMutation,
  useUpdateContributionStatusMutation,
} from '../../generated/graphql'
import { MessageType } from '../../utils/message-type'
import { useTranslation } from 'react-i18next'
import { Article, Contribution } from './types'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { useCurrentNode } from '../../contexts/CurrentNodeProvider'
import {
  ArticleEditor,
  ArticleEditorRef,
  SelectionInfo,
} from '../../components/ArticleEditor/ArticleEditor'
import { ContributionDetails } from './components/ContributionDetails'
import { ButtonsBar } from './components/ButtonsBar'
import { useAuthContext } from '../../auth/auth-context'
import { ContributionCount } from './components/ContributionCount'
import { LevelIndicator } from '../../components/LevelIndicator'
import { useTheme } from '../../hooks/use-theme'
import { ContributionInput } from './components/ContributionInput'
import { useHeaderButton } from '../../contexts/HeaderButtonProvider'
import { DefaultBar } from './components/DefaultBar'
import { IconPerspectiveSettings } from '../../utils/meldd-icons'
import { useKeyboard } from '../../hooks/use-keyboard-hook'
import { useFeatures } from '../../contexts/FeatureProvider'
import * as Clipboard from 'expo-clipboard'
import url from '../../../app.config'
import { Env } from '../../env'
import { formatSelection } from './format'
import { SocketEvents, useSocketRoom } from '../../socket/socket.hooks'

export type ArticleDetails = ArticleQuery['article']

function PerspectiveScreen({
  navigation,
  route,
}: StackScreenProps<AppNavigatorParams, 'Perspective'>) {
  const { articleId, clusterId, nodeId, showPerspectiveOwners } = route.params
  const editorRef = useRef<ArticleEditorRef>(null)
  const keyboardHeight = useKeyboard()
  const { ownerContributions } = useFeatures()
  const theme = useTheme()
  const [editorContributionId, setEditorContributionId] = useState<
    string | null
  >()
  const { userId } = useAuthContext()
  const { t } = useTranslation('articles')
  const { currentNode } = useCurrentNode(nodeId)
  const {
    setHandler: setSaveHandler,
    setMeta,
    setDisabled: setSaveDisabled,
  } = useHeaderButton<Contribution>('saveContribution')
  const { setHandler: setCancelHandler, setDisabled: setCancelDisabled } =
    useHeaderButton<Contribution>('cancelContribution')
  const [selection, setSelection] = useState({ start: 0, end: 0, html: '' })

  const [doReserveContribution] = useReserveContributionMutation()
  const [doSubmitContribution, { loading: saving }] =
    useSubmitContributionMutation({
      update: (cache, result) => {
        if (result.data) {
          const { submitContribution } = result.data
          const field = `article(${JSON.stringify({
            id: articleId,
          })})`
          cache.modify({
            id: `Article:${articleId}`,
            fields: {
              contributions: (currentContributions: Readonly<any[]>) => {
                const newContribRef = cache.writeFragment({
                  data: submitContribution,
                  fragmentName: 'ContributionEditDetail',
                  fragment: ContributionEditDetailFragmentDoc,
                })
                const newBumpRefStr = JSON.stringify(newContribRef)
                if (
                  currentContributions.some(
                    (ref) => JSON.stringify(ref) == newBumpRefStr
                  )
                ) {
                  return [...currentContributions]
                }
                return [...currentContributions, newContribRef]
              },
            },
          })
        }
      },
    })

  const [reservedContribution, _setReservedContribution] =
    useState<Contribution | null>(null)
  const { data: clusterData, loading: clusterLoading } =
    useAvailableClustersQuery({
      fetchPolicy: 'cache-only',
      variables: {
        nodeId: nodeId,
      },
    })
  const [doUpdateContributionStatus] = useUpdateContributionStatusMutation()

  const isFocused = useIsFocused()

  const {
    loading: loadingArticle,
    data,
    refetch,
  } = useArticleQuery({
    variables: { id: articleId },
    fetchPolicy: 'cache-and-network',
  })

  useSocketRoom(articleId).event(SocketEvents.REFRESH, refetch)

  const loading = loadingArticle || clusterLoading
  const contributions: Contribution[] = useMemo(
    () => data?.article.contributions || [],
    [data?.article.contributions]
  )

  const editorContributions = useMemo(
    () =>
      contributions
        .filter((c) => c.status == ContributionStatus.Pending)
        .map((c) => {
          let type = 0
          switch (c.contributionType) {
            case ContributionType.Challenge:
              type = 3
              break
            case ContributionType.Edit:
              type = 1
              break
            case ContributionType.Inquiry:
              type = 2
              break
            case ContributionType.Reserved:
              break
          }
          return {
            id: c.id,
            start: c.begin,
            end: c.end,
            type,
          }
        }),
    [contributions]
  )

  const editorContribution = useMemo(
    () =>
      editorContributionId
        ? contributions.find((c) => c.id === editorContributionId)
        : undefined,
    [editorContributionId, contributions]
  )

  // const keyboardHeight = useKeyboard()
  useEffect(() => {
    if (isFocused) {
      refetch()
    }
  }, [isFocused])

  const perspective = useMemo(() => data?.article, [data])
  const canContribute = useMemo(
    () => perspective?.ownerId !== userId || ownerContributions,
    [perspective, ownerContributions, userId]
  )

  const createContribution = async (type: ContributionType) => {
    const { start, end } = selection
    if (end - start < 1 || !perspective) {
      console.error('Wrong selection')
      return
    }
    editorRef.current?.lockSelection(true)
    const input: ReserveContributionInput = {
      articleId: articleId,
      begin: start,
      end,
      clusterId,
      basedOnVersion: perspective.version,
      intendedType: type,
    }
    try {
      const result = await doReserveContribution({ variables: { input } })
      setReservedContribution(result.data!.reserveContribution || null)
    } catch (e) {
      Toast.show((e as Error).message, MessageType.error)
      editorRef.current?.lockSelection(false)
    }
  }
  const setReservedContribution = useCallback(
    (contribution: Contribution | null) => {
      const cancelContribution = contribution
        ? async () => {
            const input: UpdateContributionInput = {
              contributionId: contribution.id,
              status: ContributionStatus.Cancelled,
            }

            try {
              setCancelDisabled(true)
              await doUpdateContributionStatus({ variables: { input } })
            } catch (e) {
              Toast.show((e as Error).message, MessageType.error)
            } finally {
              _setReservedContribution(null)
              setSaveHandler(null)
              setCancelHandler(null)
              editorRef.current?.lockSelection(false)
              editorRef.current?.clearSelection()
              setSelection({ start: 0, end: 0, html: '' })
              editorRef.current?.setContributions(
                editorContributions.filter((c) => c.id !== contribution.id)
              )
              editorRef.current?.showContributions(true)

              setMeta(null)
            }
          }
        : null

      _setReservedContribution(contribution)
      setMeta(contribution)
      setSaveHandler(() => {
        //
      })
      setCancelHandler(cancelContribution)
      setCancelDisabled(!contribution)
    },
    [_setReservedContribution, editorContributions]
  )

  const onSelection = (selectionInfo: SelectionInfo) => {
    if (!perspective) {
      return
    }
    if (selectionInfo.end === 0) {
      return
    }

    /**
     * TODO: This format is better if is done in the editor it self
     */
    const selection = formatSelection(perspective.latestContent, {
      ...selectionInfo,
    })
    setSelection(selection)
  }

  const updateSaveHandler = useCallback(
    (input: string, motivation: string) => {
      if (!reservedContribution) {
        return
      }
      const updateInput: SubmitContributionInput = {
        contributionId: reservedContribution.id,
        input:
          reservedContribution.intendedType == ContributionType.Edit
            ? input
            : input.trim(),
        motivation: motivation.trim(),
        type: reservedContribution.intendedType!,
      }
      setSaveHandler(async () => {
        try {
          setSaveDisabled(true)
          const result = await doSubmitContribution({
            variables: { input: updateInput },
          })
          editorRef.current?.lockSelection(false)
          editorRef.current?.clearSelection()
          setReservedContribution(null)
          Toast.show(t('The contribution has been made'), MessageType.info)
        } catch (e) {
          Toast.show((e as Error).message, MessageType.error)
        } finally {
          setSaveDisabled(false)
        }
      })
    },
    [reservedContribution]
  )

  useEffect(() => {
    if (editorRef.current && contributions) {
      let rContribution = contributions.find(
        (c) =>
          c.userId == userId &&
          c.contributionType === ContributionType.Reserved &&
          new Date(c.reservedUntil).getTime() > Date.now()
      )

      if (rContribution) {
        setSelection({
          start: rContribution.begin,
          end: rContribution.end,
          html: rContribution.originalText,
        })
        editorRef.current?.setSelectionFromContribution({
          id: rContribution.id,
          start: rContribution.begin,
          end: rContribution.end,
          type: 0,
        })
        setReservedContribution(rContribution)
      } else {
        setReservedContribution(null)
        setSelection({ start: 0, end: 0, html: '' })
        editorRef.current?.lockSelection(false)
        editorRef.current?.setContributions(editorContributions)
        editorRef.current?.showContributions(true)
      }
    }
  }, [contributions, editorContributions, setReservedContribution])

  if (!data && !loading) {
    Toast.show(t("We couldn't find your article"), MessageType.error)
    // navigation.pop()
  }

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'column',
          display: 'flex',
          justifyContent: 'space-evenly',
          backgroundColor: 'white',
          alignItems: 'stretch',
          overflow: 'hidden',
          maxHeight: Dimensions.get('window').height - 64,
          // paddingBottom: 0//Platform.OS=="android"? keyboardHeight-2:0,
        },
        editor: {
          flex: 1,
          // height: 200,
          flexShrink: 1,
          backgroundColor: 'white',
          paddingHorizontal: theme.spacing(2),
        },
        info: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingVertical: theme.spacing(4),
          paddingHorizontal: theme.spacing(3),
        },
        title: {
          flexDirection: 'row',
          justifyContent: 'center',
          paddingHorizontal: theme.spacing(3),
          paddingTop: theme.spacing(2),
          alignItems: 'center',
        },
        settingsButton: {
          marginTop: -theme.spacing(1),
          // borderWidth: 1,
          // borderColor: theme.colors.border,
        },
      }),
    [theme, keyboardHeight]
  )

  const savePerspectiveUrl = (perspective: Article) => {
    let urlToClipBoard = `${Env.WEB_APP_URL}/Perspective?articleId=${perspective.id}&nodeId=${nodeId}&clusterId=${perspective.clusterId}`
    Toast.show('URL copied!', {
      position: Toast.positions.TOP,
    })
    Clipboard.setString(urlToClipBoard)
  }

  const bottomBar = useMemo(() => {
    if (reservedContribution) {
      return (
        <ContributionInput
          disabled={saving}
          contribution={reservedContribution}
          inputHandler={updateSaveHandler}
        />
      )
    } else if (canContribute && selection.end - selection.start > 0) {
      return <ButtonsBar onClick={createContribution} />
    } else if (editorContribution) {
      return (
        <ContributionDetails
          contribution={editorContribution}
          onClose={() => editorRef.current?.selectContribution()}
          onNext={() => editorRef.current?.selectContributionChange(1)}
          onPrevious={() => editorRef.current?.selectContributionChange(-1)}
        />
      )
    } else {
      return (
        <DefaultBar
          articleId={articleId}
          canContribute={canContribute}
          username={perspective?.owner.username}
          ownerId={perspective?.owner.id}
          showPerspectiveOwners={showPerspectiveOwners}
        />
      )
    }
  }, [
    reservedContribution,
    selection,
    editorContribution,
    contributions,
    saving,
  ])
  return (
    <ScreenWrapper withScrollView={false} style={styles.container}>
      {perspective ? (
        <>
          {!reservedContribution && (
            <View style={styles.info}>
              <ContributionCount
                perspectiveId={perspective.id}
                contributions={perspective.contributions}
              />
              <LevelIndicator level={2} flat={true} />
            </View>
          )}
          {!reservedContribution && (
            <View style={styles.title}>
              <Text variant={'titleLarge'} style={{ textAlign: 'center' }}>
                {perspective.title}
              </Text>
              <IconButton
                size={20}
                icon="content-copy"
                onPress={() => savePerspectiveUrl(perspective)}
              />
              {perspective.ownerId === userId && (
                <IconButton
                  onPress={() =>
                    navigation.navigate('UpdateArticle', {
                      articleId,
                      clusterId: perspective.clusterId,
                      isAutoTitle: perspective.isAutoTitle,
                      latestContent: perspective.latestContent,
                      title: perspective.title,
                    })
                  }
                  style={styles.settingsButton}
                  icon={IconPerspectiveSettings}></IconButton>
              )}
            </View>
          )}
          <ArticleEditor
            style={styles.editor}
            ref={editorRef}
            initialContentHTML={perspective.latestContent}
            editorMode={'select'}
            onRefresh={refetch}
            onSelection={onSelection}
            onContribution={setEditorContributionId}
          />
          {bottomBar}
        </>
      ) : null}
    </ScreenWrapper>
  )
}

export default PerspectiveScreen
