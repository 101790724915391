import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enOrg from './lang/en.json'
import cnOrg from './lang/cn.json'
import { featureLoader } from './utils/features'
function transformStrings<T extends Record<string, any>>(obj: T): T {
  const result = {}
  // @ts-ignore
  return Object.entries(obj).reduce<T>((r, [k, v]) => {
    if (typeof v === 'string') {
      return { ...r, [k]: v.replace(/./g, 'X') }
    } else if (typeof v === 'object') {
      return { ...r, [k]: transformStrings(v) }
    }
  }, {})
}

// const en = transformStrings(enOrg)
// console.log(JSON.stringify(en, null, 2))
i18n
  .use(initReactI18next)
  .use({
    type: 'languageDetector',
    async: true,
    init() {},
    detect(cb: (str: string) => void) {
      return featureLoader.then((f) => cb(f.langCn ? 'cn' : 'en'))
    },
    cacheUserLanguage() {},
  })
  .init({
    compatibilityJSON: 'v3', // <--- https://stackoverflow.com/questions/70493788/i18nextpluralresolver-your-environment-seems-not-to-be-intl-api-compatible-u
    returnNull: false,
    fallbackLng: 'en',
    debug: true,
    resources: {
      en: enOrg,
      cn: cnOrg,
    },
    fallbackNS: 'common',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
