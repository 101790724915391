import { AppNavigatorParams } from '../../navigation/types'
import { Pressable, RefreshControl, StyleSheet, View } from 'react-native'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { AuthContext } from '../../auth/auth-context'
import { Cluster } from './types'
import { StackScreenProps } from '@react-navigation/stack'
import { useCurrentNode } from '../../contexts/CurrentNodeProvider'
import {
  ContributionStatus,
  ContributionType,
  useAvailableClustersLazyQuery,
} from '../../generated/graphql'
import { orderBy } from 'lodash'
import { useIsFocused } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { Copyright } from '../../components/Copyright'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import Animated from 'react-native-reanimated'
import { FAB, IconButton, Text, Button } from 'react-native-paper'
import {
  Icon3dContributions,
  Icon3dPerspective,
  Icon3dVoteCount,
  IconAdd,
  IconGoTo,
} from '../../utils/meldd-icons'
import { Icon } from '../../components/Icon'
import { useTheme } from '../../hooks/use-theme'
import { LevelIndicator } from '../../components/LevelIndicator'
import { useGlobalStyles } from '../../contexts/GlobalStylesProvider'
import { SocketEvents, useSocketRoom } from '../../socket/socket.hooks'

export function ClusterListScreen({
  navigation,
  route,
}: StackScreenProps<AppNavigatorParams, 'ClusterList'>) {
  const isFocused = useIsFocused()
  const { params } = route
  const { t } = useTranslation('clusters')
  const globalStyles = useGlobalStyles()
  const {
    currentNode,
    loading: loadingNodes,
    setCurrentNode,
  } = useCurrentNode(params?.external ? params?.nodeId : undefined)

  const [doLoadClusters, { data, loading, error }] =
    useAvailableClustersLazyQuery()

  const refresh = useCallback(() => {

    if (currentNode) {

      try {
        doLoadClusters({
          variables: { nodeId: currentNode.id },
          fetchPolicy: 'cache-and-network',
        })
      } catch (e) {
        setCurrentNode(undefined)
      }
    }
  }, [currentNode])

  useSocketRoom(currentNode?.id).event(SocketEvents.REFRESH, refresh)

  useEffect(() => {
    if (
      (currentNode && !params?.nodeId) ||
      (currentNode && !params.external && currentNode.id !== params.nodeId)
    ) {
      navigation.setParams({ nodeId: currentNode.id, external: undefined })
    } else if (params?.external) {
      navigation.setParams({ nodeId: params.nodeId, external: undefined })
    }
  }, [currentNode, params])
  useEffect(() => {
    if (isFocused && !loadingNodes && !currentNode) {
      console.log('ListClustersScreen no node')
      navigation.navigate('ListNodes')
      setTimeout(() => { }, 0)
    } else if (isFocused && currentNode) {
      refresh()
    }
  }, [loadingNodes, currentNode, isFocused, refresh])

  const theme = useTheme()
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(4),
      paddingRight: 0,
      borderBottomColor: theme.colors.surfaceVariant,
      borderBottomWidth: 1,
    },
    levelIndicator: {
      // width: '100%',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: theme.spacing(4),
      borderBottomColor: theme.colors.surfaceVariant,
      borderBottomWidth: 1,
    },
    info: {
      rowGap: theme.spacing(2),
      flex: 1,
    },
    topic: {},
    infoIcons: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: theme.spacing(2),
    },
    icon: {
      position: 'relative',
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: theme.spacing(2),
      width: theme.spacing(16),
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing(16),
      right: theme.spacing(4),
    },
  })
  const clusters = useMemo(() => {
    const ordered = orderBy(
      data?.availableClusters || [],
      ['contentUpdatedAt'],
      ['desc']
    )
    return ordered.map((cluster: Cluster) => {
      const onPress = () =>
        navigation.push('ClusterCards', { clusterId: cluster.id })
      const voteCount = cluster.articleVotes.reduce((acc, vote) => acc + 1, 0)
      const contributionCount = cluster.articles.reduce(
        (acc, article) =>
          acc +
          article.contributions.filter(
            (c) =>
              c.contributionType != ContributionType.Reserved &&
              c.status != ContributionStatus.Censured &&
              c.status != ContributionStatus.Cancelled
          ).length,
        0
      )
      return (
        <Animated.View key={cluster.id}>
          <Pressable onPress={onPress} style={styles.container}>
            <View style={styles.info}>
              <Text numberOfLines={3} variant={'titleLarge'}>
                {cluster.topic}
              </Text>
              <View style={styles.infoIcons}>
                <View style={styles.icon}>
                  <Icon3dPerspective
                    size={24}
                    onPress={() =>
                      navigation.push('ClusterCards', { clusterId: cluster.id })
                    }
                    label={cluster.articles.length.toString()}
                  />
                </View>
                <View style={styles.icon}>
                  <Icon3dContributions
                    size={24}
                    label={contributionCount.toString()}
                    onPress={() =>
                      navigation.push('ClusterContributions', {
                        clusterId: cluster.id,
                      })
                    }
                  />
                </View>
                <View style={styles.icon}>
                  <Icon3dVoteCount size={24} label={voteCount.toString()} />
                </View>
              </View>
            </View>
            <IconButton icon={IconGoTo} onPress={onPress} />
          </Pressable>
        </Animated.View>
      )
    })
  }, [data])

  return (
    <View
      style={[globalStyles.pageContainer, { flex: 1, position: 'relative' }]}>
      <View style={styles.levelIndicator}>
        <LevelIndicator level={0} flat={true} />
      </View>
      <ScreenWrapper
        refreshControl={
          <RefreshControl refreshing={loading} onRefresh={refresh} />
        }>
        {clusters}
        <View style={{ height: theme.spacing(32) }}>{/* spacer */}</View>
      </ScreenWrapper>
      <FAB
        disabled={!currentNode}
        style={styles.fab}
        icon={IconAdd}
        label={t('Create cluster')}
        onPress={() =>
          navigation.push('CreateCluster', {
            nodeId: currentNode!.id,
            nodeName: currentNode!.name,
          })
        }
      />
      <Copyright />
    </View>
  )
}
export default ClusterListScreen
