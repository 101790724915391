import * as React from 'react'
import { PhoneNumberUtil } from 'google-libphonenumber'
import {
  Keyboard,
  ScrollView,
  StyleProp,
  View,
  ViewStyle,
  Text,
} from 'react-native'
import { ControlledTextInput } from './ControlledTextInput'
import { useTheme } from '../../hooks/use-theme'
import { useTranslation } from 'react-i18next'
import { TextInput, Menu, Searchbar, HelperText } from 'react-native-paper'
import { Controller } from 'react-hook-form'
import { Dimensions } from 'react-native'
import { CountryList } from './CountryList'

const windowWidth = Dimensions.get('window').width

const phoneUtil = PhoneNumberUtil.getInstance()

interface ControlledMobileInputProps<A extends string, B extends string> {
  disabled?: boolean
  control: any
  getValues: (payload?: string | string[]) => Object
  trigger: (name?: (A | B) | (A | B)[]) => Promise<boolean>
  names: [A, B]
  rules?: any
  error?: any
  helperText?: string
  wrapperStyle?: StyleProp<ViewStyle>
  onIsValid?: (isValid: boolean) => void
}

export function ControlledMobileInput<A extends string, B extends string>(
  props: ControlledMobileInputProps<A, B>
) {
  const theme = useTheme()
  const { t, i18n } = useTranslation('login_register_reset')
  const [showCountryPicker, setShowCountryPicker] = React.useState(false)
  const {
    control,
    error,
    helperText,
    names,
    wrapperStyle,
    disabled,
    getValues,
    trigger,
  } = props
  const [searchQuery, setSearchQuery] = React.useState('')

  return (
    <View style={wrapperStyle}>
      <Controller
        control={control}
        name={names[0]}
        render={({ field: { onChange, onBlur, value } }) => (
          <View
            style={[
              {
                paddingVertical: theme.spacing(2),
                position: 'relative',
                flex: 0,
                flexShrink: 0,
                flexGrow: 0,
                flexBasis: '20%',
                marginRight: theme.spacing(2),
              },
            ]}>
            <Menu
              visible={showCountryPicker}
              anchorPosition={'bottom'}
              style={{
                flex: 1,
                maxHeight: 300,
                width: windowWidth - 2 * theme.spacing(4),
              }}
              onDismiss={() => setShowCountryPicker(false)}
              anchor={
                <TextInput
                  disabled={disabled}
                  style={{ top: -2 }}
                  // label={t('Country Code')}
                  value={value}
                  editable={false}
                  onTouchStart={
                    !disabled ? () => setShowCountryPicker(true) : undefined
                  }
                  right={
                    <TextInput.Icon
                      icon="menu-down"
                      disabled={disabled}
                      onPress={() => setShowCountryPicker(true)}
                    />
                  }
                  dense
                  mode="outlined"
                />
              }>
              <Searchbar
                placeholder="Search"
                onChangeText={setSearchQuery}
                value={searchQuery}
              />
              <CountryList
                lang={i18n.language}
                style={{ maxHeight: 300 }}
                searchValue={searchQuery}
                pickerButtonOnPress={(item) => {
                  onChange(item.dial_code)
                  setShowCountryPicker(false)
                  trigger(names[1]).then()
                }}
              />
            </Menu>
          </View>
        )}
      />
      <ControlledTextInput
        disabled={disabled}
        control={control}
        name={names[1]}
        keyboardType={'number-pad'}
        autoComplete="off"
        autoCapitalize="none"
        autoCorrect={false}
        rules={{
          required: t('requiredMobile'),
          validate: (value: string) => {
            let isValid = false
            const dialCode = getValues(names[0]) as string
            const region = phoneUtil.getRegionCodeForCountryCode(
              parseInt(dialCode)
            )
            try {
              const phoneNumber = phoneUtil.parse(value, region)
              isValid = phoneUtil.isValidNumber(phoneNumber)
            } catch (e) {
              console.log(e)
            }
            props.onIsValid?.(isValid)
            return isValid || t('invalidMobile')
          },
        }}
        placeholder={t('MobileNumber')}
        label={t('MobileNumber')}
        mode="outlined"
        error={error}
        helperText={helperText}
        wrapperStyle={[{ flex: 1 }]}
      />
    </View>
  )
}
