import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { LoginMutation, LoginMutationResult } from '../generated/graphql'
import { SocketClient } from '../socket/socket-client'

const CURRENT_USER_KEY = 'currentUser'
const LAST_USERNAME_KEY = 'lastUsername'
const LAST_USER_DATA_KEY = 'lastUserData'
const NO_USER: CurrentUser = {
  token: '',
  userId: '',
  id: '',
  username: '',
  firstName: '',
  lastName: '',
  mobileNumber: '',
  mobileCountryCode: 0,
  isAdmin: false,
}

type AuthContextProviderProps = {
  children: ReactNode
}

export type AuthenticatePayload = Omit<LoginMutation['login'], '__typename'>

export type CurrentUser = LoginMutation['login']['user'] & {
  token: string
  userId: string
}
export type AuthContextShape = CurrentUser & {
  lastUserData: LastUserData
  loading: boolean
  setCurrentUser: (payload: AuthenticatePayload) => void
  clearCurrentUser: () => void
  updateUserData: (payload: AuthenticatePayload['user']) => void
}
export type LastUserData = {
  username?: string
  mobileNumber?: string
  mobileCountryCode?: number
}
export const AuthContext = createContext<AuthContextShape>({
  ...NO_USER,
  loading: true,
  isAdmin: false,
  lastUserData: {},
  firstName: '',
  lastName: '',
  setCurrentUser: (_payload: AuthenticatePayload) => { },
  clearCurrentUser: () => { },
  updateUserData: (_payload: AuthenticatePayload['user']) => { },
})

export const AuthContextProvider = (props: AuthContextProviderProps) => {
  const [currentUser, _setCurrentUser] = useState<CurrentUser>(NO_USER)
  const [lastUserData, setLastUserData] = useState<LastUserData>({})
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(true)
    AsyncStorage.getItem(LAST_USER_DATA_KEY)
      .then((str) => {
        let userData: any

        try {
          userData = JSON.parse(str || '{}')
          setLastUserData(userData)
        } catch (e) { }
        if (!userData.username) {
          return AsyncStorage.getItem(LAST_USERNAME_KEY)
        }
      })
      .then((str) => {
        if (str) {
          setLastUserData({ username: str })
        }
      })
    AsyncStorage.getItem(CURRENT_USER_KEY).then((str) => {
      setLoading(false)
      if (str) {
        const data = JSON.parse(str) as CurrentUser
        SocketClient.init({ userId: data.userId, token: data.token })
        _setCurrentUser(data)
      } else {
        _setCurrentUser(NO_USER)
      }
    })
  }, [])

  const setCurrentUser = useCallback((payload: AuthenticatePayload) => {
    if (!payload) {
      throw Error('payload is null')
    }
    const { accessToken, user } = payload
    if (!accessToken || !user.username || !user.id) {
      throw Error('Cannot authenticate, token or username or userId are null')
    }
    const lastUserData = {
      username: user.username,
      mobileNumber: user.mobileNumber,
      mobileCountryCode: user.mobileCountryCode,
    }
    const currentUser: CurrentUser = {
      ...user,
      userId: user.id,
      token: accessToken,
    }
    SocketClient.init({ userId: user.id, token: accessToken })
    _setCurrentUser(currentUser)
    setLastUserData(lastUserData)
    // noinspection JSIgnoredPromiseFromCall
    AsyncStorage.setItem(CURRENT_USER_KEY, JSON.stringify(currentUser))
    // noinspection JSIgnoredPromiseFromCall
    AsyncStorage.setItem(LAST_USER_DATA_KEY, JSON.stringify(lastUserData))
  }, [])

  const updateUserData = useCallback((user: AuthenticatePayload['user']) => {
    if (!user) {
      throw Error('payload is null')
    }
    (AsyncStorage.getItem(CURRENT_USER_KEY) || '{}').then((json) => {
      if (!json) { return }
      const data = JSON.parse(json) as { token: string }
      const userUpdated: CurrentUser = {
        ...user,
        userId: user.id,
        token: data.token,
      }

      const lastUserData = {
        username: user.username,
        mobileNumber: user.mobileNumber,
        mobileCountryCode: user.mobileCountryCode,
      }
      // TODO: THis line needs to be uncommented ... but is making an infite loop. Help!
      // _setCurrentUser({ ...userUpdated })
      setLastUserData(lastUserData)
      // noinspection JSIgnoredPromiseFromCall
      AsyncStorage.setItem(CURRENT_USER_KEY, JSON.stringify(userUpdated))
      // noinspection JSIgnoredPromiseFromCall
      AsyncStorage.setItem(LAST_USER_DATA_KEY, JSON.stringify(lastUserData))
    })
  }, [])

  const clearCurrentUser = useCallback(() => {
    _setCurrentUser(NO_USER)
    SocketClient.disconnect();
    // noinspection JSIgnoredPromiseFromCall
    AsyncStorage.removeItem(CURRENT_USER_KEY)
  }, [])

  const value: AuthContextShape = useMemo(() => {
    return {
      ...currentUser,
      lastUserData,
      updateUserData,
      setCurrentUser,
      clearCurrentUser,
      loading,
    }
  }, [currentUser, loading])

  return <AuthContext.Provider value={value} {...props} />
}

export const useAuthContext = () => useContext(AuthContext)
