import * as React from 'react'
import { Appbar, IconButton } from 'react-native-paper'
import { StackHeaderProps, StackNavigationProp } from '@react-navigation/stack'
import { AppNavigatorParams } from '../navigation/types'
import { View } from 'react-native'
import NodeSelector from './NodeSelector'
import { useCurrentNode } from '../contexts/CurrentNodeProvider'
import NavigationBarMenu from './NavigationBarMenu'
import { useTheme } from '../hooks/use-theme'
import { DecisionsButton } from '../screens/Contributions/components/DecisionsButton'
import { CoRankButton } from './CoRankButton'
import { MelddToolTip } from '../utils/meldd-tooltip'
import { useTranslation } from 'react-i18next'

export interface NodeNavigationBarProps extends StackHeaderProps {
  navigation: StackNavigationProp<AppNavigatorParams>
}

export function NodeNavigationBar({
  navigation,
  route,
  options,
  back,
}: NodeNavigationBarProps) {
  const theme = useTheme()
  const { nodes, refetch, loading, setCurrentNode, currentNode } =
    useCurrentNode()
  const { t } = useTranslation('header')
  return (
    <Appbar.Header style={{ backgroundColor: theme.colors.secondaryContainer }}>
      <View
        style={{
          flex: 1,
          marginHorizontal: 12,
          flexDirection: 'row',
          flexWrap: 'nowrap',
          alignItems: 'center',
        }}
      >
        <View style={{ flex: 0, flexBasis: '100%', maxWidth: 300 }}>
          <NodeSelector
            selected={currentNode}
            nodes={nodes}
            onSelect={setCurrentNode}
          />
        </View>
        {currentNode && (
          <MelddToolTip title={t('info')}>
            <IconButton
              disabled={!currentNode}
              icon="information-outline"
              onPress={() => navigation.push('NodeStats', { node: currentNode })}
            />
          </MelddToolTip>
        )}
      </View>
      <CoRankButton />
      <DecisionsButton />

      <NavigationBarMenu navigation={navigation} />
    </Appbar.Header>
  )
}
